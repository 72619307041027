<template>
    <div id="about">
        <PageTitle :title="title" />
        <div class="content-container">
            <div id="agent">
                <div class="profilePhoto">
                    <img id="agentPhoto" :src="img" alt="Bob Safranski"/>
                </div>
                <div class="content">
                    <p v-html="content"></p>
                </div>
            </div>
            <Reviews id="reviews" />
        </div>
    </div>
</template>

<script>
import PageTitle from "../components/PageTitle"
import Reviews from "../components/Reviews.vue";

export default {
    name: "About",
    components: {
    PageTitle,
    Reviews
},
    data(){
        return{
            title: "Get to Know Bob",
            content: `Bob Safranski has been a licensed real estate broker since 2002, and his multilingual skills in French and Japanese were honed during two years spent in both countries during his college years. These linguistic strengths served him well in his early career with a French-Japanese joint venture. However, his passion for real estate led him to pivot towards the industry, seeking to serve clients with unparalleled dedication. By 2005, he founded Saffron Realty Group. This brokerage, renowned for its exceptional service, grew impressively to house 35 agents and later merged with the esteemed @properties, even amidst challenging market conditions.
            <br><br>Always at the forefront of innovation, Bob's interest in leveraging technology for real estate materialized in 2014 with the launch of offMLS, a platform for agents to exchange off-market listings. This venture was soon followed by the inception of Zenlist, co-created with partners as the ultimate real estate search and collaboration tool for agents and clients alike. As he continues to explore new groundbreaking projects in the realm of real estate, Bob's primary mission remains consistent: ensuring the perfect transaction for every client.`,
            img: "/images/site_owner.jpg",
        }
    },
    metaInfo: {
        title: 'About | Bob Safranski',
        meta: [{
            name: 'description', 
            content: "Mindful real estate"
        }]
    },
}
</script>

<style lang="scss" scoped>
.content-container{
    margin: 0 40px;
}
#agent{
    display: flex;
    flex-direction: row;
    align-items: center;
    .content{
        flex: 1;
        text-align: left;
        p{
            margin: 0 10%;
        }
    }
    .profilePhoto{
        width: 25%;
        img{
            object-fit: cover;
            width: 100%;
            display: block;
        }
    }
    margin-bottom: 3rem;
}
@media (max-width: 780px) { 
    .profilePhoto{
        margin-right: 1rem;
    }
}


@media (max-width: $mobile) { 
    .content-container{
        margin: 0 20px;
    }
    #agent{
        flex-wrap: wrap;
        text-align: center;
        .profilePhoto{
            width: 80%;
            margin: 2rem auto;
            #agentPhoto{
                object-fit: cover;
                object-position: 0 0;
                height: 300px;
            }
        }
        .content{
            text-align: center;
            padding-bottom: 3rem;
        }
    }
}
</style>