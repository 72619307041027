<template>
    <section>
        <div class="header">
            <hr>
            <img src="/images/badge-1.png" alt="good reviews">
            <img src="/images/badge-2.png" alt="good reviews">
            <hr>
        </div>
        <div v-if="reviews.length" class="reviews">
            <div class="column" v-for="i in rows" :key="i">
                <div 
                    class="review-container"
                    v-for="j in reviewsLength"
                    :key="i*j"
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-duration="1000"
                    v-bind:data-aos-delay="i*100">
                    <img src="/images/five-stars.svg" alt="">
                    <hr>
                    <h3 class="excerpt">
                        {{ reviews[(i-1) + (j-1)*rows].Excerpt }}
                    </h3>
                    <p class="review">
                        "{{ reviews[(i-1) + (j-1)*rows].Review }}"
                    </p>
                    <p class="initials">
                        - {{ reviews[(i-1) + (j-1)*rows].Initials  }}
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name:"Reviews",
    computed:{
        reviews(){
            return this.$store.getters.reviews.filter(review => !review.Homepage);
        },
        rows(){
            if(window.innerWidth > 980){
                return Math.min(3, this.reviews.length);
            }
            if(window.innerWidth > 576){
                return Math.min(2,  this.reviews.length);
            }
            return 1;
        },
        reviewsLength(){
            return Math.floor(this.reviews.length / this.rows);
        }
    }
}
</script>

<style lang="scss" scoped>
h1{
    font-family: $secondaryFont;
    text-align: center;
    margin: 3.2rem auto;
    font-size: 2.55rem;
    font-weight: 500;
}

.header{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    img{
        width: 140px;
        margin:0 20px;
    }
    hr{
        width: 80%;
        align-self: center;
        opacity: 0.5;
        margin: 0 20px;
    }
}

.reviews{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.column{
    width: 33.33%;
}

.review-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background: #f6f6f6;
    padding: 30px;
    margin: 20px;
    hr{
        width: 80%;
        align-self: center;
        opacity: 0.5;
        margin-top: 20px
    }
    img{
        align-self: center;
    }
    .excerpt{
        font-style: italic;
        font-size: 40px;
        font-weight: 600;
        font-family: $secondaryFont;
    }
    .initials{
        margin-top: 30px;
        font-weight: 700;
    }
}

@media (max-width: $tablet) {
    .column{
        width: 50%;
    }
}
@media (max-width: $mobile) {
    .column{
        width: 100%;
    }
    .header{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        img{
            width: 25%;
            margin:0 5px;
        }
        hr{
            width: 80%;
            align-self: center;
            opacity: 0.5;
            margin: 0 5px;
        }
    }
}
</style>